import packageInfo from '../../package.json';

export const environment = {
  apiUrl: 'https://api.skillhop.com/v1/',
  production: true,
  sentryProduction: true,
  enableServiceWorker: true,
  version: packageInfo.version,
  pusher_key: '35baba195c7bffb6bbc8',
  hotjar_key: 3003610,
  sentry_dsn: 'https://22b452a4745046f89ca47328bf253762@o945779.ingest.sentry.io/6482597',
  syncfusion: 'ORg4AjUWIQA/Gnt2UVhhQlVFfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hTX5SdENjWntddX1XRGdd',
  test_user_m: '',
  test_user_m2: '',
  test_user_c: '',
  test_pass: '',
};

console.log(packageInfo.version);
console.log('environment: prod');

console.log = () => {};
